<template>
  <div class="flex items-center ml-5">
    <span class="mr-10">
      {{ $t('admin.documentList.searchCount', { count }) }}
    </span>
    <button
      class="pagination-button"
      :disabled="paginationButtonsDisabled"
      @click="onPageClick(1)"
    >
      <svg-icon name="chevron-double-left" />
    </button>
    <button
      class="pagination-button"
      :disabled="paginationButtonsDisabled"
      @click="goToBeforePage"
    >
      <svg-icon name="chevron-left" />
    </button>
    <button
      v-for="i in shownPages"
      :key="`page-${i}`"
      class="pagination-button"
      :class="{ active: currentPage === i }"
      @click="onPageClick(i)"
    >
      {{ i }}
    </button>
    <button
      class="pagination-button"
      :disabled="paginationButtonsDisabled"
      @click="goToNextPage"
    >
      <svg-icon name="chevron-right" />
    </button>
    <button
      class="pagination-button"
      :disabled="paginationButtonsDisabled"
      @click="onPageClick(pageCount)"
    >
      <svg-icon name="chevron-double-right" />
    </button>
  </div>
</template>

<script>
const PAGESIZE = 10;

export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.count / PAGESIZE);
    },
    shownPages() {
      const pages = [];
      if (this.currentPage > 2 && this.currentPage + 2 < this.pageCount) {
        for (let i = this.currentPage - 2; i <= this.currentPage + 2; i++) {
          pages.push(i);
        }
      } else {
        if (this.currentPage < 3) {
          for (let i = 1; i <= Math.min(5, this.pageCount); i++) {
            pages.push(i);
          }
        } else {
          // If page count is 3 or 4 we need to subtract the current page by 1 and not 2 because,
          // we only display 3 or 4 entries. If its subtract by 2 it will show page 0.
          let subtractBy = this.pageCount > 4 ? 2 : 1;

          for (
            let i =
              this.currentPage -
              subtractBy -
              (this.currentPage + 2 - this.pageCount);
            i <= this.pageCount;
            i++
          ) {
            if (i === 0) {
              continue;
            }
            pages.push(i);
          }
        }
      }
      return pages;
    },
    paginationButtonsDisabled() {
      return this.pageCount === 1;
    },
  },
  methods: {
    onPageClick(i) {
      this.currentPage = i;
      this.$emit('goto', i);
    },
    goToNextPage() {
      if (this.currentPage < this.pageCount) {
        this.onPageClick(this.currentPage + 1);
      }
    },
    goToBeforePage() {
      if (this.currentPage >= this.pageCount && this.pageCount !== 1) {
        this.onPageClick(this.currentPage - 1);
      }
    },
    setCurrentPage(i) {
      this.currentPage = i;
    },
  },
};
</script>

<style lang="postcss" scoped>
.pagination-button {
  @apply w-10 h-10;
  @apply inline-flex justify-center items-center;
  @apply rounded;

  &:disabled {
    @apply pointer-events-none !text-gray-300 cursor-not-allowed;
  }
}

.backend .pagination-button {
  @apply text-blue-600;
  @apply hover:bg-blue-700 hover:text-white;

  &.active {
    @apply bg-blue-700 text-white shadow-inner-backend;
  }
}
.frontend .pagination-button {
  @apply text-secondary-600;
  @apply hover:bg-secondary-800 hover:text-white;

  &.active {
    @apply bg-secondary-800 text-white shadow-inner-frontend;
  }
}
</style>
