<template>
  <multiselect
    v-bind="$attrs"
    :select-label="$i18n.t('general.multiselect.selectLabel')"
    :deselect-label="$i18n.t('general.multiselect.deselectLabel')"
    :selected-label="$i18n.t('general.multiselect.selectedLabel')"
    placeholder="Bitte wählen"
    v-on="$listeners"
  >
    <span slot="noResult">
      {{ $t('general.multiselect.noResult') }}
    </span>
    <span slot="noOptions">
      {{ $t('general.multiselect.noOptions') }}
    </span>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'MultiselectWrapper',
  components: {
    Multiselect,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
