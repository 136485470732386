<template>
  <div
    class="relative dropbox h-[274px]"
    :class="{ 'dropbox--error': !!error }"
  >
    <input
      :accept="acceptedFileType"
      type="file"
      name="file"
      class="input-file"
      @change="fileChange"
    />
    <svg-icon class="w-20 h-20 mb-10 text-blue-600" :name="uploadIcon" />
    <div v-if="file">
      <p class="text-center">{{ file.name }}</p>
    </div>
    <p v-else class="px-3 text-center text-base">
      {{ !error ? dragAndDropText : error }}
    </p>
    <div v-if="file" class="z-10">
      <button
        class="mt-2 text-lg text-blue-600 underline text-base"
        @click.prevent.stop="deleteFile"
      >
        {{ $t('upload.deleteFile') }}
      </button>
    </div>
    <div v-else-if="!error">
      <button class="mt-2 text-lg text-blue-600 underline text-base">
        {{ $t('upload.clickHere') }}
      </button>
    </div>
  </div>
</template>

<script>
const PDF_MODE = 'pdf';
const IMAGE_MODE = 'image';

const MAX_SIZE = process.env.VUE_APP_MAX_IMAGE_UPLOAD * 1024 * 1024;

export default {
  props: {
    acceptedFileType: {
      type: String,
      default: 'application/pdf',
    },
    mode: {
      type: String,
      default: 'pdf',
    },
  },
  data() {
    return {
      file: null,
      error: '',
    };
  },
  computed: {
    dragAndDropText() {
      return this.mode === PDF_MODE
        ? this.$t('upload.dragAndDropPdf')
        : this.$t('upload.dragAndDropImage');
    },
    uploadIcon() {
      if (this.file) return 'file-check';
      if (this.error) return 'warning-circle-outline';
      return this.mode === PDF_MODE ? 'add-file' : 'picture';
    },
  },
  methods: {
    fileChange(event) {
      if (!event.target.files?.[0]) {
        return;
      }
      const file = event.target.files[0];
      if (!this.acceptedFileTypeMatches(file.type)) {
        this.error = this.$t('upload.typeError_' + this.mode);
        this.startErrorScheduler();
        event.target.value = '';
        return;
      } else if (this.mode === IMAGE_MODE && file.size > MAX_SIZE) {
        this.error = this.$t('upload.sizeError_' + this.mode);
        this.startErrorScheduler();
        event.target.value = '';
        return;
      }

      this.file = file;
      this.$emit('input', this.file);
    },
    deleteFile() {
      this.file = null;
      this.$emit('input', this.file);
    },
    acceptedFileTypeMatches(fileType) {
      return this.acceptedFileType.split(',').includes(fileType);
    },
    startErrorScheduler() {
      setTimeout(() => {
        this.error = '';
      }, 5000);
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropbox {
  @apply flex flex-col items-center justify-center;
  @apply border-dashed border border-blue-700 rounded;
  @apply h-[274px];
  @apply pb-4;
  position: relative;
  cursor: pointer;

  &.dropbox--error {
    @apply bg-blue-500 bg-opacity-[15%];
    @apply border-4 border-solid;
  }
}

.input-file {
  @apply absolute top-0;
  @apply w-full h-full;
  @apply !opacity-0;
  @apply cursor-pointer;
}
</style>
