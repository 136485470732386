<template>
  <div :class="containerClasses" class="mt-[5px]">
    <div
      v-if="hasStateIcons"
      class="state"
      :class="documentStateClass"
      :title="stateTooltips"
    ></div>
    <div v-if="hasLabels">
      {{ stateLabels }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentState',
  props: {
    document: {
      type: Object,
      required: true,
    },
    hasLabels: {
      type: Boolean,
      default: false,
    },
    hasStateIcons: {
      type: Boolean,
      default: true,
    },
    containerClasses: {
      type: String,
      Array,
      Object,
      default: '',
    },
  },
  computed: {
    stateLabels() {
      switch (this.document.validityState) {
        case 'is_in_order':
          return this.$t('documentState.inOrder');
        case 'is_not_yet_in_order':
          return this.$t('documentState.notYetInOrder');
        case 'is_out_of_order':
          return this.$t('documentState.isOutOfOrder');
        default:
          return this.$t('documentState.edit');
      }
    },
    documentStateClass() {
      switch (this.document.validityState) {
        case 'is_in_order':
          return 'in-order';
        case 'is_not_yet_in_order':
          return 'not-yet-in-order';
        case 'is_out_of_order':
          return 'out-of-order';
        default:
          return 'edit';
      }
    },
    stateTooltips() {
      switch (this.document.validityState) {
        case 'is_in_order':
          return this.$t('tooltips.state.inOrder');
        case 'is_not_yet_in_order':
          return this.$t('tooltips.state.notYetInOrder');
        case 'is_out_of_order':
          return this.$t('tooltips.state.outOfOrder');
        default:
          return this.$t('tooltips.state.edit');
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.state {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  @apply mr-2;

  &.in-order {
    @apply bg-green-500;
  }

  &.not-yet-in-order {
    @apply bg-orange-400;
  }

  &.out-of-order {
    @apply bg-primary-400;
  }

  &.edit {
    @apply bg-blue-800;
  }
}
</style>
