<template>
  <modal :is-open="isOpen" class-modal="backend" @closeModal="closeModal">
    <template #header>
      <h3 class="text-4xl leading-snug">{{ $t('upload.uploadForm.title') }}</h3>
    </template>

    <label-field
      :label="$t('upload.uploadForm.labels.language')"
      class="pr-0 mt-4"
      is-block
      :label-class="[]"
      :required="true"
    >
      <single-select
        id="uploadDocumentLanguage"
        v-model="language"
        name="languageSelect"
        :options="availableLanguages"
        select-classes="upload-language-select"
        option-lookup="abbr"
        display-lookup="value"
      />
    </label-field>

    <upload-file-zone v-model="pdf" class="mt-4" />

    <input id="isFileOnly" v-model="isFileOnly" type="checkbox" class="mt-8" />
    <label for="isFileOnly" class="ml-1">
      {{ $t('upload.uploadForm.labels.isFileOnly') }}
    </label>

    <template #footer>
      <div class="flex justify-between space-x-6">
        <button class="btn btn--secondary" @click="cancelUpload">
          {{ $t('general.cancel') }}
        </button>
        <button
          class="btn btn--primary"
          :disabled="$v.$invalid"
          @click="uploadFile"
        >
          {{ $t('general.save') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import 'vue2-datepicker/index.css';
import UploadFileZone from './UploadFileZone.vue';
import SingleSelect from '@/components/SingleSelect';
import LabelField from '@/components/LabelField';
import Modal from '@/components/Modal';
import EditorEventBus from '@/helpers/EditorEventBus';
import { postDocument, addNewLanguage } from '@/services/document';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'UploadForm',
  components: {
    Modal,
    UploadFileZone,
    SingleSelect,
    LabelField,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isLanguageAdd: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: null,
    },
    isFromContextMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pdf: null,
      isFileOnly: false,
      taskId: null,
      documentId: null,
      language: '',
    };
  },
  computed: {
    availableLanguages() {
      const languages = [
        { abbr: 'de', value: this.$t('upload.uploadForm.languages.de') },
        { abbr: 'fr', value: this.$t('upload.uploadForm.languages.fr') },
        { abbr: 'it', value: this.$t('upload.uploadForm.languages.it') },
        { abbr: 'en', value: this.$t('upload.uploadForm.languages.en') },
      ];

      if (this.document && 'id' in this.document) {
        // Only return the languages which aren't already in use by the document.
        return languages.filter((l) => {
          return !this.document.documentLanguages.includes(l.abbr);
        });
      }
      return languages;
    },
  },
  validations: {
    pdf: {
      required,
    },
    language: {
      required,
    },
  },
  methods: {
    closeModal() {
      this.pdf = null;
      this.isFileOnly = false;
      this.language = '';
      this.$emit('close');
    },
    cancelUpload() {
      this.closeModal();
    },
    async goToDocument() {
      const language = this.language;
      this.closeModal();
      await this.$router.push({
        name: 'adminEditor',
        params: {
          id: this.documentId,
          taskId: this.taskId,
        },
        query: {
          activeLang: language,
        },
      });
    },
    async switchLanguageTab() {
      const language = this.language;
      this.closeModal();
      await EditorEventBus.$emit('addNewLanguageTab', language, this.taskId);
    },
    async uploadFile() {
      try {
        let documentData = null;
        if (!this.isLanguageAdd) {
          documentData = await postDocument({
            pdf: this.pdf,
            importType: this.isFileOnly ? 'file_only' : 'full',
            language: this.language,
            keywords: [],
            predecessor: null,
            successor: null,
            tags: [],
          });
        } else {
          documentData = await addNewLanguage(this.document.id, {
            ...this.document,
            pdf: this.pdf,
            language: this.language,
          });
        }

        this.documentId = documentData.id;
        this.taskId = documentData.taskId;
        if (!this.isLanguageAdd || this.isFromContextMenu) {
          await this.goToDocument();
        } else {
          await this.switchLanguageTab();
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message ||
            this.$t('upload.uploadForm.errors.general').toString(),
        });
      }
    },
  },
};
</script>
<style lang="postcss">
.upload-language-select {
  @apply border border-blue-700;
}
</style>
